import React from "react";
import appConfig from "app.config";
import BlockToReact from "@sanity/block-content-to-react";

// Components
import { OneOff } from "./OneOff";
import { HeaderAndBody } from "components/Portfolio/HeaderAndBody";
import { FullWidthImage } from "components/Portfolio/FullWidthImage";
import { InlineImage } from "components/Portfolio/InlineImage";
import { TwoPicture } from "components/Portfolio/TwoPicture";
import { AutoplayVideo } from "components/Portfolio/AutoplayVideo";
import { FileLink } from "./FileLink";

// Style
import s from "./index.module.scss";

export const BodyContent = ({ className, blocks, ...props }) => {
  return (
    <div className={`${s.bodyContent} ${className}`}>
      <BlockToReact
        blocks={blocks}
        projectId={appConfig.sanity.id}
        dataset={appConfig.sanity.dataset}
        serializers={{
          types: {
            oneOff: OneOff,
            headerAndBody: HeaderAndBody,
            fullWidthImage: FullWidthImage,
            inlineImage: InlineImage,
            twoPicture: TwoPicture,
            autoplayVideo: AutoplayVideo,
          },
          marks: {
            fileLink: FileLink
          }
        }}
        {...props}
      />
    </div>
  );
};
