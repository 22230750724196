import React from "react";
import appConfig from "app.config";

import BlockContent from "@sanity/block-content-to-react";

export const IntroDescription = ({ blocks }) => {
  const serializers = {
    types: {
      block: (props) => (
        <p size="s7" family="source-sans" weight="normal" color="grey-2">
          {props.children}
        </p>
      ),
    },
  };

  return (
    <BlockContent
      blocks={blocks}
      projectId={appConfig.sanity.id}
      dataset={appConfig.sanity.dataset}
      serializers={serializers}
    />
  );
};
