import React from "react";

// Components

// A single block to account for all custom components
export const OneOff = ({ node }) => {
  // Depending on what the component value is, render that specific component
  switch (node.component) {
    // The Gideon accordion component
    case "gideon-accordion":
      return <div />;
    case "magic":
      return <div />;
    default:
      return null;
  }
};
