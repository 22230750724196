import React, { useState, useEffect } from "react";
import { getData } from "utils/sanity/";

// Queries
import { userQuery } from "utils/sanity/sanity-queries";

const UserContext = React.createContext({});

const UserProvider = (props) => {
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [users, setUsers] = useState([
    {
      username: "",
      message1: "",
      message2: "",
      message3: "",
      email: "",
      selectedProjects: [],
    },
  ]);

  useEffect(() => {
    async function fetchUserData() {
      const userResponse = await getData(userQuery);
      setUsers(userResponse);
      await setUsersLoaded(true);
    }
    fetchUserData();
  }, []);

  return (
    <UserContext.Provider value={{ users, usersLoaded }}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
