import React, { useContext, useEffect } from "react";
import gsap from "gsap";

import { inDevelopment } from "utils/env-utils";

// Contexts
import { UserContext } from "contexts/UserContext";
import { ProjectsContext } from "contexts/ProjectsContext";

// Images
import me from "img/thumb_about.png";

// Style
import s from "./index.module.scss";

import { Header } from "components/Header";
import { ProjectCard } from "components/ProjectCard";
import { useEdgeManager } from "contexts/EdgeManager";

export const Grid = () => {
  const { users, usersLoaded } = useContext(UserContext);
  const { projects, loaded, dataLoaded } = useContext(ProjectsContext);
  const { setEdge, bgColor } = useEdgeManager();

  // Data testing
  if (inDevelopment) {
    console.group("Data Status");
    console.log(loaded ? "I'm done loading" : "I'm still loading");
    console.log(
      dataLoaded
        ? "and my data is done loading too!"
        : "but my data is still loading"
    );
    console.log(projects);
    console.groupEnd();
  }

  useEffect(() => {
    gsap.to("#edge", { backgroundColor: bgColor });
    document.title = "Jono is off the grid";
    setEdge(true);
  }, [bgColor, setEdge]);

  const aboutProject = {
    title: "About Jono",
    tile: me,
    slug: "telling-you-about-himself",
    clientName: "your consideration",
    bannerImageBackgroundColor: "yellow",
    projectColor: "#FFD700",
  };

  return (
    <div className="pageOffset">
      <div className={s.grid}>
        {projects.map((project, i) => (
          <ProjectCard project={project} key={i} />
        ))}
        <ProjectCard project={aboutProject} />
      </div>
    </div>
  );
};
