import React, { useState, useEffect, useMemo } from "react";
import gsap, { drawSVG } from "gsap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import s from "./index.module.scss";
import { SunMoonIcon } from "./SunMoonIcon";
import { useEdgeManager } from "contexts/EdgeManager";

export function DarkModeToggle({ className }) {
  // Get the slug
  let location = useLocation();
  const [theme, setTheme] = useState("light");
  const  {setIsDark}  = useEdgeManager();

  /**
   * Utility function to calculate the current theme setting.
   * Look for a local storage value.
   * Fall back to system setting.
   * Fall back to light mode.
   */
  const calculateSettingAsThemeString = ({
    localStorageTheme,
    systemSettingDark,
  }) => {
    if (localStorageTheme !== null) {
      return localStorageTheme;
    }

    if (systemSettingDark.matches) {
      return "dark";
    }

    return "light";
  };

  const handleThemeToggle = (currentThemeSetting) => {
    const newTheme = currentThemeSetting === "dark" ? "light" : "dark";
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
    currentThemeSetting = newTheme;
  };

  // Run every timne button is clicked
  useEffect(() => {
    document.querySelector("html").setAttribute("data-theme", theme);
  }, [theme]);

  // Setup function for theme on first pageLoad
  useEffect(() => {
    /**
     * 1. Grab what we need from the DOM and system settings on page load
     */
    const localStorageTheme = localStorage.getItem("theme");
    const systemSettingDark = window.matchMedia("(prefers-color-scheme: dark)");
    /**
     * 2. Work out the current site settings
     */
    let currentThemeSetting = calculateSettingAsThemeString({
      localStorageTheme,
      systemSettingDark,
    });

    setTheme(currentThemeSetting);
  }, []);
  useEffect(() => {
    if (theme === "dark") {
      setIsDark(true);
    } else {
      setIsDark(false);
    }
  }, [setIsDark, theme]);

  return (
    <button
      onClick={() => {
        handleThemeToggle(theme);
      }}
      type="button"
      className={className}
      style={{ background: "none", border: "none" }}
      data-theme-toggle
      aria-label={`Change to ${theme === "dark" ? "light" : "dark"} theme`}
    >
      <SunMoonIcon isDark={theme === "dark"} />
    </button>
  );
}
