import React, { useMemo, useEffect, useRef } from "react";

// Style
import s from "./index.module.scss";

export const AutoplayVideo = ({ node }) => {
  const { src } = node;
  const videoRef = useRef(null);

  console.log(node);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Start video playback
        } else {
          // Pause video playback
        }
      });
    });

    observer.observe(videoRef.current);

    return () => observer.disconnect();
  }, []);

  const videoElement = useMemo(() => {
    return (
      <video className={s.video} ref={videoRef} autoPlay muted loop playsInline>
        <source
          src={src}
          type="video/mp4"
        />
      </video>
    );
  }, [src]);

  return <>{videoElement}</>;
};
