import React, { useState, useEffect } from "react";
import { getData } from "utils/sanity/";

// Queries
import { selectedProjectsQuery } from "utils/sanity/sanity-queries";
import { selectedProjectsWithDataQuery } from "utils/sanity/sanity-queries";

const ProjectsContext = React.createContext({});

const ProjectsProvider = (props) => {
  // Set two loading states for without and with data
  const [loaded, setLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  // Set a project state
  const [projects, setProjects] = useState([]);

  // Get the data from Projects
  useEffect(() => {
    async function fetchData() {
      try {
        const projectsResponse = await getData(selectedProjectsQuery);
        setProjects(projectsResponse);
        setLoaded(true);
        // Get the rest of the data
        let projectsWithDataResponse = await getData(
          selectedProjectsWithDataQuery,
        );
        setProjects(projectsWithDataResponse);
        setDataLoaded(true);
      } catch (err) {
        console.log("fetch failed", err);
        throw err;
      }
    }
    fetchData();
  }, []);

  return (
    <ProjectsContext.Provider value={{ loaded, dataLoaded, projects }}>
      {props.children}
    </ProjectsContext.Provider>
  );
};

export { ProjectsContext, ProjectsProvider };
