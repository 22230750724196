import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { gsap } from "gsap/gsap-core";

// Components
import { DarkModeToggle } from "components/DarkModeToggle";

// Contexts
import { useEdgeManager } from "contexts/EdgeManager";

// Style
import s from "./index.module.scss";

export function Header({ y }) {
  const { edge, edgeColor } = useEdgeManager();
  const [isActive, setIsActive] = useState(false),
    [isHidden, setIsHidden] = useState(false),
    [previousScroll, setPreviousScroll] = useState(0);
  let location = useLocation();
  const navigate = useNavigate();
  var party = gsap.timeline({ paused: true, id: "", repeat: -1 });

  useEffect(() => {
    party
      .to("#logo", { fill: "#FF00FF" })
      .to("#logo", { fill: "#FFFF00" })
      .to("#logo", { fill: "#00FFFF" });
  }, [party]);

  // a little magic to make the header appear when a user scrolls down
  useEffect(() => {
    y > previousScroll ? setIsHidden(true) : setIsHidden(false);
    setPreviousScroll(y);
    console.log(isHidden);
  }, [y, previousScroll, isHidden]);

  return (
    <React.Fragment>
      {!edge && <div className={s.headerScrim} />}
      <div
        className={`
        ${s.header} ${!isActive && isHidden ? s.hidden : ""} ${
          location.pathname !== "/" ? s.fixed : ""
        }`}
      >
        <div className={s.spacer}>
          {location.pathname !== "/" && (
            <button onClick={() => navigate(-1)} className={s.homeLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
              >
                <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
              </svg>
            </button>
          )}
        </div>
        <Link
          className={s.headerLogoContainer}
          to="/#home"
          onClick={() => {
            setIsActive(false);
            party.pause();
            gsap.to("#logo", { fill: "currentColor" });
          }}
          onMouseEnter={() => {
            party.play(0);
          }}
          onMouseLeave={() => {
            party.pause();
            gsap.to("#logo", { fill: "currentColor" });
          }}
        >
          <svg
            className={s.logo}
            version="1.1"
            id="logo"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            fill="currentColor"
            viewBox="0 0 96.1 69.9"
            style={{ enableBackground: "new 0 0 96.1 69.9" }}
            xmlSpace="preserve"
          >
            <path d="M21.6,56.9H14v-10h7.6c7.6,0,13.4-6.3,13.4-14V25H14V15h31v17.6C45,45.8,34.8,56.9,21.6,56.9z" />
            <path d="M74.6,15H82v10h-7.4c-0.1,0-0.1,0-0.2,0c-7.6,0.2-13.6,6.4-13.4,14v8h19l-4.3,10H51V39.3C51,26.1,61.3,15,74.6,15z" />
            <path d="M96.1-0.4H0v70.3h96.1V-0.4z M8,61.9V7.6h80.1v54.3H8z" />
          </svg>
        </Link>
        <svg
          className={`${s.hamburger} ${isActive ? s.cross : ""}`}
          onClick={() => setIsActive(!isActive)}
          viewBox="0 0 800 600"
        >
          <path
            d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
            id={s.top}
          ></path>
          <path d="M300,320 L540,320" id={s.middle}></path>
          <path
            d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
            id={s.bottom}
            transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
          ></path>
        </svg>
        <nav className={`${s.desktopLinks} ${isActive ? s.isActive : ""}`}>
          {/* <Link
            className={s.headerLink}
            to="/making-you-scroll"
            onClick={() => setIsActive(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="currentColor"
            >
              <path d="M760-200v-120H200v120h560Zm0-200v-160H200v160h560Zm0-240v-120H200v120h560ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Z" />
            </svg>
          </Link> */}
          <Link
            className={s.headerLink}
            to="/off-the-grid"
            onClick={() => setIsActive(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="currentColor"
            >
              <path d="M120-520v-320h320v320H120Zm0 400v-320h320v320H120Zm400-400v-320h320v320H520Zm0 400v-320h320v320H520ZM200-600h160v-160H200v160Zm400 0h160v-160H600v160Zm0 400h160v-160H600v160Zm-400 0h160v-160H200v160Zm400-400Zm0 240Zm-240 0Zm0-240Z" />
            </svg>
          </Link>
          <Link
            className={s.headerLink}
            to="/telling-you-about-himself"
            onClick={() => setIsActive(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="currentColor"
            >
              <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
            </svg>
          </Link>
          <Link
            className={s.headerLink}
            to="mailto:jono@jono.is?subject=I'd%20like%20to%20work%20with%20you"
            onClick={() => setIsActive(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="currentColor"
            >
              <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z" />
            </svg>
          </Link>
          <DarkModeToggle className={s.headerLink} />
        </nav>
      </div>
    </React.Fragment>
  );
}
