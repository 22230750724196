export const FileLink = ({ children, mark }) => {
  // The file reference in the asset object has the form <_file>-<id>-<extension>
  // We split the text string to get the individual pieces of information.
  const [_file, id, extension] = mark.file.asset._ref.split('-');
  // The URL to access your file should be of the form
  // https://cdn.sanity.io/files/{PROJECT_ID}/{DATASET}/{FILE ID}.{FILE EXTENSION}
  return (
    <a target="_blank" rel="noreferrer" href={`https://cdn.sanity.io/files/${process.env.REACT_APP_SANITY_PROJECT_ID}/${process.env.REACT_APP_SANITY_DATASET}/${id}.${extension}`}>
      {children}
    </a>
  );
}