import React from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap/gsap-core";

import { useEdgeManager } from "contexts/EdgeManager";

// Style
import s from "./index.module.scss";

export const ProjectCard = ({ project }) => {
  const { isDark, bgColor, fgColor } = useEdgeManager();
  return (
    <Link
      to={`/${project.slug}`}
      className={s.projectCard}
      onMouseEnter={() => {
        gsap.to("#edge", { backgroundColor: project.projectColor });
        gsap.to("#logo", { fill: project.projectColor });
      }}
      onMouseLeave={() => {
        gsap.to("#edge", {
          backgroundColor: bgColor,
          clearProps: "backgroundColor",
        });
        gsap.to("#logo", { fill: fgColor, clearProps: "fill" });
      }}
    >
      <img src={project.tile} alt="tile" className={s.tile} />
      <div className={s.titleContainer} style={{color: project.projectColor}}>
        <h3 className={s.title}>{project.title}</h3>
        <p className={s.subtitle}><span>for</span> {project.clientName}</p>
      </div>
    </Link>
  );
};
