import React, { useEffect, useContext, useState } from "react";
import { useParams, useNavigate, Redirect } from "react-router-dom";
import gsap from "gsap";

import { inDevelopment } from "utils/env-utils";
import { gradientStyleToBottom } from "utils/style-utils";
import styled from "styled-components";

import { IntroPicture } from "components/Portfolio/IntroPicture";
import { Intro } from "components/Portfolio/Intro";
import { BodyContent } from "components/Sanity/index";
import { ProjectFooter } from "./ProjectFooter";

// Contexts
import { ProjectsContext } from "contexts/ProjectsContext";

// Style
import s from "./index.module.scss";
import { useEdgeManager } from "contexts/EdgeManager";

export const ProjectPage = () => {
  const { projects, loaded, dataLoaded } = useContext(ProjectsContext);
  const { setEdge, setEdgeColor } = useEdgeManager();
  const [footerProjects, setFooterProjects] = useState();
  const { bgColor } = useEdgeManager();
  let { slug: urlSlug } = useParams(),
    navigate = useNavigate();

  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

  const projectSlugs = projects.map((project) => {
    return project.slug;
  });

  const slugMatch = projectSlugs.includes(urlSlug);

  // This is where we set the current active project
  const project = projects.find(({ slug }) => {
    return slug === urlSlug;
  });

  const {
    jonoIs,
    bannerImage,
    bannerImageBackgroundColor,
    projectColor,
    headline,
    clientName,
    description,
    hats,
    projectLink,
    gradient,
    title,
    slug,
    data,
    index = projects.indexOf(project) // Set the index of the current project
  } = { ...project };

function getNextProjects(projects, offset) {
    var nextProjects = [];
    for (var i = 1; i<4; i++) {
        var ind = (offset + i) % projects.length;
        nextProjects.push(projects[ind]);
    }
    console.log(offset)
    console.log(nextProjects)
    return nextProjects;
}


  useEffect(() => {
    if (loaded) {
      if (slugMatch) {
        document.title = `Jono is ${jonoIs}`;
        setEdgeColor(projectColor);
        gsap.to("#edge", { backgroundColor: bgColor });
        setEdge(false);
        console.log(getNextProjects(projects, index));
        setFooterProjects(getNextProjects(projects, index));
      } else {
        navigate("/404");
      }
    }
  }, [loaded, navigate, project, jonoIs, slugMatch, setEdge, setEdgeColor, bannerImageBackgroundColor, projectColor, bgColor, projects, index]);

  if (inDevelopment) {
    console.group("Data Status");
    console.log(loaded ? "I'm done loading" : "I'm still loading");
    console.log(
      dataLoaded
        ? "and my data is done loading too!"
        : "but my data is still loading"
    );
    console.groupEnd();
  }

  const ProjectContent = project
    ? styled.div`
        h2 {
          border-left: 10px solid;
          border-image-source: ${gradientStyleToBottom(project.gradient)};
          border-image-slice: 1;
          padding-left: 1rem;
        }
        a {
          color: ${project.projectColor};
          &:hover {
            color: ${project.bannerImageBackgroundColor};
          }
        }
      `
    : styled.div``;

  //A change
  return (
    loaded &&
    slugMatch && (
      <div className={s.projectPage}>
        <IntroPicture
          image={bannerImage}
          backgroundColor={bannerImageBackgroundColor}
        />
        <Intro
          title={title}
          clientName={clientName}
          description={description}
          headline={headline}
          hats={hats}
          link={projectLink}
          projectColor={projectColor}
          gradientValues={gradient}
        />
        {/* Render below the fold on data fetch */}
        <ProjectContent className={s.projectContent}>
          {dataLoaded && data && <BodyContent blocks={data} />}
        </ProjectContent>
        {loaded && footerProjects && (
          <ProjectFooter currentProject={project} projects={footerProjects} />
        )}
      </div>
    )
  );
};
