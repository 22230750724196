import React, { useState } from "react";

import Tilt from "react-parallax-tilt";

// Style
import s from "./index.module.scss";

export const IntroPicture = ({ image, backgroundColor, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <div
      className={s.introPictureBack}
      style={{ backgroundColor: `${backgroundColor}` }}
    >
      {" "}
      <div className={s.introPictureContainer}>
          <Tilt
            className={s.tilt}
            tiltMaxAngleX="4.20"
            tiltMaxAngleY="4.20"
            gyroscope
          >
            <div className={s.introShadow}></div>
            <img
              alt=""
              className={`${s.introPicture} ${!loaded && s.hidden}`}
              src={image}
              onLoad={() => setLoaded(true)}
            />
          </Tilt> 
      </div>
    </div>
  );
};
