import React, { useEffect, useRef, useState, useContext } from "react";
import { Link } from "react-router-dom";

import { inDevelopment } from "utils/env-utils";

// Contexts
import { UserContext } from "contexts/UserContext";
import { ProjectsContext } from "contexts/ProjectsContext";

import { useSpring, animated } from "react-spring";

import s from "./index.module.scss";

export const Feed = () => {
  const { users, usersLoaded } = useContext(UserContext);
  const { projects, loaded, dataLoaded } = useContext(ProjectsContext);

  const [focusedProj, setFocusedProj] = useState({
    bg: "#FFFFFF",
    img: "",
    slug: "https://www.crouton.net",
  });

  // Data testing
  if (inDevelopment) {
    console.group("Data Status");
    console.log(loaded ? "I'm done loading" : "I'm still loading");
    console.log(
      dataLoaded
        ? "and my data is done loading too!"
        : "but my data is still loading"
    );
    console.log(projects);
    console.groupEnd();
  }

  return (
    <div className={`pageOffset ${s.feed}`}>
      <Link to={`/${focusedProj.slug}`} className={s.introText}>
        Jono is{" "}
        <div className={s.scrollContainer}>
          {projects.map((project) => <h2 className="greeting">{project.jonoIs}</h2>)}
        </div>
      </Link>
    </div>
  );
};
