import React from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";

import { useEdgeManager } from "contexts/EdgeManager";

import s from "./index.module.scss"


export const UserButton = ({ user }) => {
  const { isDark, fgColor, bgColor } = useEdgeManager();
  return (
    <Link
      className={`button tall ${s.button}`}
      to={`/showing-his-work-to/${user.slug}`}
      onMouseEnter={() => {
        gsap.to("#edge", {backgroundColor: user.edgeColor})
        gsap.to("#logo", {fill: user.edgeColor})
      }}
      onMouseLeave={() => {
        gsap.to("#edge", {backgroundColor: bgColor, clearProps: "backgroundColor"})
        gsap.to("#logo", {fill: fgColor, clearProps: "fill"})
      }}
    >
      {user.emoji} {user.username}
    </Link>
  );
};
