import React from "react";

// Style
import s from "./index.module.scss";

export const InlineImage = ({ node }) => {
  const { image, backgroundColor, roundedCorners } = node;

  console.log(node);

  return <img alt="" className={s.inlineImage} src={image} style={{backgroundColor: backgroundColor, borderRadius: roundedCorners ? "20px" : 0 }} />;
};
