import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";

// Components
import { BodyContent } from "components/Sanity";

// Contexts
import { useEdgeManager } from "contexts/EdgeManager";

// Utils
import { aboutQuery } from "utils/sanity/sanity-queries";
import { getData } from "utils/sanity";
import { gradientStyleToBottom } from "utils/style-utils";

// Style
import s from "./index.module.scss";

export const About = () => {
  const [aboutData, setAboutData] = useState({});
  const [aboutColor, setAboutColor] = useState("#DC9A00")
  const {about, headshot} = aboutData;

  const {setEdge} = useEdgeManager();

  useEffect(() => {
    async function fetchData() {
      try {
        const aboutData = await getData(aboutQuery);
        setAboutData(aboutData);
      } catch (err) {
        console.log("fetch failed", err);
        throw err;
      }
    }
    fetchData();
  }, []);

  // Setup function
  useEffect(() => {
    gsap.to("#edge", { backgroundColor: aboutColor })
    document.title = "Jono is telling you about himself"
    setEdge(true);
  }, [aboutColor, aboutData, setEdge]);

  const AboutContent = aboutData
  ? styled.div`
      h2 {
        border-left: 10px solid;
        border-image-source: ${gradientStyleToBottom(["red", "blue"])};
        border-image-slice: 1;
        padding-left: 1rem;
      }
      a {
        color: ${aboutColor};
        &:hover {
          color: ${aboutColor};
        }
      }
    `
  : styled.div``;

  return (
    about && (
      <div className={`pageOffset ${s.container}`}>
        <img src={headshot} className={s.headshot} id="edge" alt="headshot" />
        <AboutContent><BodyContent blocks={about} className={s.aboutContent}/></AboutContent>
      </div>
    )
  );
};
