import React from "react";

// Style
import s from "./index.module.scss";

export const TwoPicture = ({ node }) => {
  const { image1, image2 } = node;

  console.log(node);

  return <div className={s.container}>
  <img alt="" className={s.image} src={image1} />
  <img alt="" className={s.image} src={image2} />
  </div>
};
